import React from 'react';
import { toPhoneNumberNational } from '@/libs/app';
import { Avatar, Link } from '@/components';
import { PagePath } from '@/layouts/shared';

type PartnerCardProps = {
  partner: any;
};

const PartnerCard: React.FC<PartnerCardProps> = (props) => {
  const { partner } = props;
  const avatarUrl = partner?.avatar;
  const bannerUrl = partner?.partner_stores?.banner_image?.url;

  return (
    <Link url={{ pathname: PagePath.PartnerDetail, query: { id: partner.id }}}>
      <div className="tw-h-[320px] tw-border tw-border-gray-70 hover:tw-border-primary-main tw-bg-white tw-rounded-3xl tw-overflow-hidden">
        <div
          className="tw-h-[150px] tw-bg-center tw-bg-cover tw-bg-no-repeat tw-relative"
          style={{
            backgroundImage: `url(${bannerUrl})`,
          }}
        >
          <div className="tw-absolute tw-left-3 tw-bottom-3 tw-z-10">
            <Avatar
              src={avatarUrl}
              className="tw-w-20 tw-h-20"
              rounded="tw-rounded-full"
            />
          </div>
          <div
            className="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full"
            style={{
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.46))',
            }}
          ></div>
        </div>
        <div className='tw-p-4'>
          <h3 className='tw-text-primary-main tw-font-bold'>{partner.fullname}</h3>
          <p className='tw-font-medium'>{toPhoneNumberNational(partner.phone, partner.country, true)}</p>
          <p className='tw-text-secondary-81 tw-text-[14px] tw-leading-7 tw-font-medium tw-whitespace-pre-wrap tw-line-clamp-3'>{partner.partner_stores?.description}</p>
        </div>
      </div>
    </Link>
  );
};

export default PartnerCard;
