import React, { memo, useEffect, useRef, useState } from 'react';
import { HomeSliderProps, SliderMediaType } from '../../const';
import { Image, Link } from '@/components';
import ReactPlayer from 'react-player';

const HomeSlider = ({ images }: HomeSliderProps) => {
  const [slideIndex, setSlideIndex] = useState<number>(1);
  const [videoHeight, setVideoHeight] = useState<string>('');

  const refDiv = useRef<any>(null);
  const playerRef = useRef<ReactPlayer | any>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (images ?? [])[slideIndex - 1]?.media_type === SliderMediaType.Image
      ) {
        if (slideIndex < images.length) {
          setSlideIndex((slideIndex) => slideIndex + 1);
        } else if (slideIndex === images.length) {
          setSlideIndex(1);
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [slideIndex, images]);

  useEffect(() => {
    if (refDiv) {
      setVideoHeight(refDiv?.current?.clientHeight);
    }
  }, []);

  const moveDot = (index: number) => {
    setSlideIndex(index);
  };

  const playerConfig: any = {
    attributes: {
      with: 2000
      // height: videoHeight,
    },
    youtube: {
      playerVars: {
        autoplay: 1,
        mute: 1,
        controls: 0,
        modestbranding: 1, // Removes YouTube logo
        rel: 0, // Prevents showing related videos at the end
        showinfo: 0, // Hides video information
      },
    },
  };
  const items = Array.isArray(images) ? images : [];

  return (
    <>
      <div className="tw-w-full tw-h-full tw-relative tw-flex tw-pb-40-percent md:tw-pb-35-percent">
        <div
          ref={refDiv}
          className="tw-w-full tw-h-full home-slider tw-relative"
        >
          {items.map((item, index) => {
            return (
              <div
                key={item.title + '-' + index}
                className={`tw-w-full tw-bg-center tw-bg-cover tw-absolute tw-top-0 tw-left-0 tw-bottom-0  tw-pb-40-percent md:tw-pb-35-percent ${
                  slideIndex === index + 1
                    ? 'slider-item active'
                    : 'slider-item tw-hidden'
                }`}
              >
                {item.media_type === SliderMediaType.Image && (
                  <Link url={images[slideIndex - 1]?.link ?? ''}>
                    <Image
                      src={item.image_url}
                      alt={item.title}
                      priority={slideIndex === index + 1 ? true : false}
                      className="tw-w-full tw-h-full !tw-absolute tw-top-0 tw-left-0"
                    />
                  </Link>
                )}
                {item.media_type === SliderMediaType.Video && (
                  <div className="video-wrapper">
                    <ReactPlayer
                      url={item.video_url}
                      width="100%"
                      height="100%"
                      className="react-player home-slider-video tw-w-full tw-h-full !tw-absolute tw-top-0 tw-left-0"
                      ref={playerRef}
                      autoPlay
                      playing={true}
                      loop={true}
                      controls={false}
                      muted={true}
                      config={playerConfig}
                      onReady={() => {
                        (playerRef.current as ReactPlayer)?.seekTo(0, 'seconds');
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="tw-absolute slider-index-dots tw-gap-x-2 md:tw-gap-x-3 tw-flex">
          {items.map((item, index) => (
            <div
              onClick={() => moveDot(index + 1)}
              key={item.title + '-' + index}
              className={`tw-w-2 tw-h-2 md:tw-w-4 md:tw-h-4 tw-rounded-full ${
                slideIndex === index + 1 ? 'tw-bg-primary-main' : 'tw-bg-white'
              }`}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(HomeSlider);
