import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';
import { Image, Icon, ArrowLeftIcon } from '@/components';
import { useFetchPartnersForBanner } from '../../api';
import PartnerCard from './PartnerCard';

type PartnersProps = {
  title: string;
};

const PartnersPartial = (props: PartnersProps) => {
  const { title } = props;

  const [partners, setPartners] = useState<any[]>([]);
  const { refetch: fetchPartners, isLoading: isLoadingNews } =
    useFetchPartnersForBanner({
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setPartners(data.data),
    });

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]);

  const swiperRef = useRef<any>();

  if (!partners?.length) {
    return <></>;
  }

  return (
    <div
      className="tw-w-full tw-h-[540px] md:tw-h-[380px] tw-bg-cover tw-grid tw-gr"
      style={{ backgroundImage: 'url("/bg-partner-section.png")' }}
    >
      <div className="container tw-mx-auto tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-pt-8">
        <div
          className={`md:tw-pr-6 ${
            partners.length === 1 && 'tw-col-span-1 md:tw-col-span-2'
          }`}
        >
          <div className="tw-text-lg md:tw-text-2xl tw-flex tw-items-center tw-flex-1">
            <div className="tw-flex tw-items-center tw-w-max tw-justify-center">
              <Image
                src="/handshake.svg"
                alt="handshake"
                className="tw-h-6 tw-w-6"
              />
              <h2
                className={`tw-flex-1 tw-block tw-px-4 tw-uppercase tw-text-primary-main tw-font-semibold`}
              >
                {title}
              </h2>
              <Image
                src="/handshake.svg"
                alt="handshake"
                className="tw-h-6 tw-w-6"
              />
            </div>
          </div>
          <div
            className={`tw-text-secondary-70 tw-font-medium tw-pt-6 tw-leading-7`}
          >
            <p>
              An Thư Diamond Store tự hào hợp tác cùng những doanh nghiệp kim
              hoàn hàng đầu, mang đến những sản phẩm chất lượng cao và đa dạng.
            </p>
          </div>
        </div>
        <div
          className={`tw-col-span-1 md:tw-col-span-2 ${
            partners.length === 1 && 'md:!tw-col-span-1'
          }`}
        >
          {partners?.length > 0 && (
            <div className="tw-block tw-relative md:tw-mt-4">
              {partners.length > 1 && (
                <button
                  className="tw-hidden custom-prev tw-w-10 tw-h-10 tw-rounded-full tw-absolute tw-top-1/2 -tw-translate-y-[50%] tw--left-10 lg:tw-flex tw-items-center tw-justify-center tw-z-10 tw-text-primary-main"
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <Icon
                    className="tw-w-full tw-h-full"
                    icon={<ArrowLeftIcon />}
                  />
                </button>
              )}
              <Swiper
                slidesPerView={partners.length === 1 ? 1 : 2}
                spaceBetween={16}
                keyboard={{
                  enabled: true,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: partners.length === 1 ? 1 : 1.5,
                    spaceBetween: 8,
                  },
                  768: {
                    slidesPerView: partners.length === 1 ? 1 : 2,
                    spaceBetween: 16,
                  },
                  1024: {
                    slidesPerView: partners.length === 1 ? 1 : 2,
                    spaceBetween: 16,
                  },
                }}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                modules={[EffectFade, Navigation]}
                className="product-loop-swiper tw-overflow-auto category-scrollbar"
                id="swiper"
              >
                {partners?.map((item: any, index: number) => (
                  <SwiperSlide
                    key={`item-${index}`}
                    className={`tw-relative ${
                      partners.length === 1 && 'tw-flex-1'
                    }`}
                  >
                    <PartnerCard partner={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {partners.length > 1 && (
                <button
                  className="custom-next tw-hidden tw-w-10 tw-h-10 tw-absolute tw--right-10 tw-top-1/2 -tw-translate-y-[50%] tw-text-primary-main lg:tw-flex tw-items-center tw-justify-center tw-rotate-180 tw-z-10"
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <Icon
                    className="tw-w-full tw-h-full"
                    icon={<ArrowLeftIcon />}
                  />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnersPartial;
